<template>
  <div class="whiteList">
    <!-- <div class="main-Title bgff"><h2>Supplement List</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="100px">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
              <el-form-item label="发布内容" class="mb0">
                <el-input
                  clearable
                  v-model.number="filterForm.content"
                  placeholder="Please enter content"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
              <el-form-item label-width="0" class="mb0">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
                <el-button
                  type="primary"
                  @click="openDialog('add')"
                  :disabled="accountRoles.indexOf('TechTeam') < 0"
                  >Create</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width:100%;min-height: 70vmin;"
          stripe
          border
          :data="list"
          highlight-current-row
          aria-setsize="mini"
          size="mini"
          class="mb10"
        >
          <el-table-column label="版本号" prop="version" align="center" width="180">
          </el-table-column>
          <el-table-column
            label="发布时间"
            prop="releaseTime"
            align="center"
            width="180"
          ></el-table-column>
          <el-table-column label="发布内容" prop="content" align="center">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.content"
                readonly
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 15 }"
                placeholder="Please enter content"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="负责人" prop="head" align="center" width="180"></el-table-column>
          <el-table-column label="operation" align="center" width="180">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="deletes(scope.row.id)"
                :disabled="accountRoles.indexOf('TechTeam') < 0"
                >Delete</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="openDialog('edit', scope.row)"
                :disabled="accountRoles.indexOf('TechTeam') < 0"
                >Edit</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width="30%">
          <el-form
            v-model="postData"
            size="small"
            :model="postData"
            ref="postDataForm"
            label-width="100px"
            :rules="rules"
          >
            <el-form-item label="版本号" prop="version">
              <el-input v-model="postData.version" placeholder="Please enter version"></el-input>
            </el-form-item>
            <el-form-item label="发布时间" prop="releaseTime">
              <el-date-picker
                v-model="postData.releaseTime"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="发布内容" prop="content">
              <el-input
                v-model="postData.content"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 15 }"
                placeholder="Please enter content"
              ></el-input>
            </el-form-item>
            <el-form-item label="负责人" prop="head">
              <el-input v-model="postData.head" placeholder="Please enter head"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog('postDataForm')" size="mini">取 消</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="submitDialog('postDataForm')"
              :loading="loading.submitBtn"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import versionLogListCon from '../controllers/setting/versionLog';
  export default {
    name: 'versionLogList',
    ...versionLogListCon,
  };
</script>

<style></style>
