import request from 'utils/request';
const api = {
  VERSION_LOG_PAGE: '/api/versionLog/list',
  VERSION_LOG_DELETES: '/api/versionLog/delete/',
  VERSION_LOG_ADD: '/api/versionLog/add',
  VERSION_LOG_UPDATE: '/api/versionLog/update',
};

export function fetchVersionLogPage(params) {
  return request({
    url: api.VERSION_LOG_PAGE,
    method: 'get',
    params,
  });
}

export function handleVersionLogDelete(id) {
  return request({
    url: api.VERSION_LOG_DELETES + id,
    method: 'delete',
  });
}

export function handleVersionLogAdd(data) {
  return request({
    url: api.VERSION_LOG_ADD,
    method: 'post',
    data,
  });
}

export function handleVersionLogUpdate(data) {
  return request({
    url: api.VERSION_LOG_UPDATE,
    method: 'post',
    data,
  });
}
