import {
  fetchVersionLogPage,
  handleVersionLogAdd,
  handleVersionLogDelete,
  handleVersionLogUpdate,
} from '@/api/versionLog';
import Pagination from '@/components/pagination';
import { mapActions, mapState } from 'vuex';
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      filterForm: {
        page: 1,
        pageSize: 20,
      },
      total: 0,
      postData: {},
      list: [],
      loading: {
        list: false,
      },
      dialog: {
        visible: false,
        title: '--',
        type: '',
      },
      rules: {
        version: [{ required: true, message: '版本号不能为空' }],
        releaseTime: [{ required: true, message: '发布时间不能为空' }],
        content: [{ required: true, message: '发布内容不能为空' }],
        head: [{ required: true, message: '负责人不能为空' }],
      },
    };
  },
  mounted() {
    this.getList();
    this.selectAccountRoles();
  },
  computed: {
    ...mapState('user', {
      accountRoles: (state) => state.roles,
    }),
  },
  methods: {
    ...mapActions('user', ['selectAccountRoles']),
    // 获取列表
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      this.loading.list = true;
      fetchVersionLogPage(this.filterForm)
        .then((res) => {
          if (res.code == 200) {
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e);
        });
    },
    // 删除
    deletes(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          handleVersionLogDelete(id).then((res) => {
            if (res.code == 200) {
              this.$message.success('删除成功！');
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 打开弹窗
    openDialog(type, row) {
      this.dialog.type = type;
      this.dialog.title = type == 'add' ? '添加' : '编辑';
      this.dialog.visible = true;
      if (row) {
        this.postData = Object.assign({}, row);
      } else {
        this.postData = {};
      }
      if (this.$refs['postDataForm']) {
        this.$refs['postDataForm'].resetFields();
      }
    },
    // 提交弹窗
    submitDialog(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading.submitBtn = true;
          if (this.dialog.type == 'add') {
            handleVersionLogAdd(this.postData)
              .then((res) => {
                if (res.code == 200) {
                  this.$message.success('添加成功!');
                  this.loading.submitBtn = false;
                  this.closeDialog(formName);
                  this.getList();
                } else {
                  this.$message.error(res.message);
                  this.loading.submitBtn = false;
                }
              })
              .cached(() => {
                this.loading.submitBtn = false;
              });
          }
          if (this.dialog.type == 'edit') {
            handleVersionLogUpdate(this.postData)
              .then((res) => {
                if (res.code == 200) {
                  this.$message.success('更新成功!');
                  this.loading.submitBtn = false;
                  this.closeDialog(formName);
                  this.getList();
                } else {
                  this.$message.error(res.message);
                }
                this.dialog.btn = false;
              })
              .cached(() => {
                this.loading.submitBtn = false;
              });
          }
        }
      });
    },
    // 关闭弹窗
    closeDialog(formName) {
      this.$refs[formName].resetFields();
      this.dialog.visible = false;
    },
    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
  },
};
